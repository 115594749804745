import styled, { css } from 'styled-components';
import { getOpacity } from '../../../theme/utils/get-rgb';

export const Wrapper = styled.span.withConfig({
    shouldForwardProp: (prop) => !['cursor', 'block'].includes(prop),
})<{
    cursor: boolean;
    block: boolean;
}>`
    ${({ cursor, block }) => css`
        position: relative;
        display: ${block ? 'block' : 'inline-flex'};
        cursor: ${cursor ? 'help' : 'unset'};
    `}
`;

export const Trigger = styled.span.withConfig({
    shouldForwardProp: (prop) => !['block'].includes(prop),
})<{
    block: boolean;
}>`
    ${({ block }) => css`
        display: ${block ? 'block' : 'inline-flex'};
        align-items: center;
        gap: 1rem;
    `}
`;

export const TriggerChildren = styled.span.withConfig({
    shouldForwardProp: (prop) => !['block'].includes(prop),
})<{
    block: boolean;
}>`
    ${({ block }) => css`
        display: ${block ? 'block' : 'inline-block'};
    `}
`;

export const Content = styled.span.withConfig({
    shouldForwardProp: (prop) => !['position', 'icon', 'small', 'arrowPosition'].includes(prop),
})<{
    position?: 'bottom' | 'top' | 'top-right' | 'bottom-right' | 'bottom-left' | 'top-left';
    icon?: boolean;
    size: 'small' | 'medium';
    arrowPosition?: number;
}>`
    ${({ theme, size, position, icon, arrowPosition }) => css`
        position: absolute;
        transition: opacity ${theme.base.transition};
        z-index: 9999;
        max-width: 30rem;
        background: ${theme.palette.color.secondary.main};
        color: ${theme.palette.common.textInverse};
        border-radius: 0.8rem;
        text-align: center;
        text-transform: none;
        padding: ${size === 'medium' ? '1.6rem' : '0.8rem 1rem'};
        font-size: ${size === 'medium' ? theme.text.size.small.fontSize : 'unset'};
        line-height: ${size === 'medium' ? theme.text.size.medium.lineHeight : 'unset'};
        box-shadow: 0 0 0.6rem 0.2rem ${getOpacity(theme.palette.background.body, 0.3)};

        &:after {
            border: ${size === 'small' ? '0.5rem' : '0.8rem'} solid rgba(0, 0, 0, 0);
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        --top-position: ${size === 'small' ? '-1rem' : '-1.6rem'};
        --bottom-position: ${size === 'small' ? '1rem' : '1.6rem'};

        ${icon &&
        css`
            --horizontal-position: ${size === 'small' ? '1rem' : '1.6rem'};
            --horizontal-position-negative: ${size === 'small' ? '-1rem' : '-1.6rem'};
        `}

        ${!icon &&
        css`
            --horizontal-position: 0rem;
            --horizontal-position-negative: 0rem;
        `}

        ${arrowPosition &&
        css`
            --arrow-position: ${arrowPosition}rem;
        `}

        ${!arrowPosition &&
        css`
            --arrow-position: ${size === 'small' ? '1.2rem' : '1.6rem'};
        `}

        ${position === 'bottom' &&
        css`
            transform: translateY(var(--bottom-position));

            &:after {
                left: 50%;
                transform: translateX(-50%);
                bottom: 100%;
                border-bottom-color: ${theme.palette.color.secondary.main};
            }
        `}

        ${position === 'top' &&
        css`
            transform: translateY(var(--top-position));

            &:after {
                top: 100%;
                border-top-color: ${theme.palette.color.secondary.main};
                left: 50%;
                transform: translateX(-50%);
            }
        `}

        ${position === 'top-left' &&
        css`
            transform: translate(var(--horizontal-position-negative), var(--top-position));

            &:after {
                top: 100%;
                border-top-color: ${theme.palette.color.secondary.main};
                left: var(--arrow-position);
            }
        `}

        ${position === 'top-right' &&
        css`
            transform: translate(var(--horizontal-position), var(--top-position));

            &:after {
                top: 100%;
                border-top-color: ${theme.palette.color.secondary.main};
                right: var(--arrow-position);
            }
        `}

        ${position === 'bottom-right' &&
        css`
            transform: translate(var(--horizontal-position), var(--bottom-position));

            &:after {
                right: var(--arrow-position);
                bottom: 100%;
                border-top-color: rgba(0, 0, 0, 0);
                border-bottom-color: ${theme.palette.color.secondary.main};
            }
        `}

        ${position === 'bottom-left' &&
        css`
            transform: translate(var(--horizontal-position-negative), var(--bottom-position));

            &:after {
                left: var(--arrow-position);
                bottom: 100%;
                border-top-color: rgba(0, 0, 0, 0);
                border-bottom-color: ${theme.palette.color.secondary.main};
            }
        `}
    `}
`;

export const IconWrapper = styled.span`
    position: relative;
    display: inline-flex;
    color: inherit;

    i {
        width: 1.4rem;
        height: 1.4rem;
    }
`;
