import React from 'react';
import { Wrapper } from './text.styled';
import { GapSizeType } from '../../../theme/type/gap-type';
import { TextSizeType, TextVariantType } from '../../../theme/type/text-type';

interface TextPropsInterface {
    tag?: React.ElementType | keyof JSX.IntrinsicElements;
    gap?: GapSizeType;
    marginTop?: GapSizeType;
    marginLeft?: GapSizeType;
    marginRight?: GapSizeType;
    marginBottom?: GapSizeType;
    children: React.ReactNode;
    align?: string | object;
    variant?: TextVariantType;
    size?: TextSizeType | object;
    bold?: boolean;
    semiBold?: boolean;
    italic?: boolean;
    uppercase?: boolean;
    nowrap?: boolean;
}

export const Text: React.FC<TextPropsInterface> = ({
    children,
    tag,
    variant = 'default',
    gap = 'none',
    marginTop = 'none',
    marginBottom = 'none',
    marginLeft = 'none',
    marginRight = 'none',
    size = 'medium',
    align,
    bold,
    semiBold,
    italic,
    uppercase,
    nowrap,
}) => {
    return (
        <Wrapper
            as={tag || `p`}
            gap={gap}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            variant={variant}
            align={align}
            size={size}
            bold={bold}
            semiBold={semiBold}
            italic={italic}
            uppercase={uppercase}
            nowrap={nowrap}
        >
            {children}
        </Wrapper>
    );
};
