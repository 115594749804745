import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconHelpFilled: React.FC = () => (
    <Icon viewBox="0 0 384 384" fill="none">
        <g>
            <path
                d="M192 0C86 0 0 86 0 192C0 298 86 384 192 384C298 384 384 298 384 192C384 86 298 0 192 0ZM202.44 302H172.23C171.55 301.997 170.899 301.725 170.419 301.244C169.939 300.762 169.67 300.11 169.67 299.43V269.23C169.67 268.55 169.939 267.898 170.419 267.416C170.899 266.935 171.55 266.663 172.23 266.66H202.44C203.12 266.663 203.771 266.935 204.251 267.416C204.731 267.898 205 268.55 205 269.23V299.43C205 300.11 204.731 300.762 204.251 301.244C203.771 301.725 203.12 301.997 202.44 302ZM219.44 203C203.23 213.88 201 223.85 201 233V244C201 244.796 200.684 245.559 200.121 246.121C199.559 246.684 198.796 247 198 247H176C175.204 247 174.441 246.684 173.879 246.121C173.316 245.559 173 244.796 173 244V233C173 211.09 183.08 193.67 203.82 179.74C223.1 166.8 234 158.6 234 140.57C234 128.31 227 119 212.51 112.11C209.1 110.49 201.51 108.91 192.17 109.02C180.45 109.17 171.35 111.97 164.34 117.61C151.12 128.25 150 139.84 150 140C149.451 143.399 149.17 146.837 149.16 150.28C149.16 151.076 148.844 151.839 148.281 152.401C147.719 152.964 146.956 153.28 146.16 153.28H124.91C124.165 153.284 123.446 153.011 122.891 152.514C122.337 152.016 121.987 151.331 121.91 150.59C121.55 146.597 121.58 142.578 122 138.59C122.22 136.16 123.8 114.27 146.77 95.79C158.68 86.21 173.83 81.23 191.77 81.01C204.47 80.86 216.4 83.01 224.49 86.83C248.7 98.34 262 117.43 262 140.57C262 174.4 239.39 189.59 219.44 203Z"
                fill="currentColor"
            />
        </g>
    </Icon>
);
