import React from 'react';
import { Wrapper } from './icon-button.styled';
import { IconSpinner } from '../../icons/spinner/spinner';
import {
    IconButtonSizeType,
    IconButtonVariantType,
    IconButtonVariationType,
} from '../../../../theme/type/icon-button-type';

interface ButtonIconPropsInterface {
    icon?: React.ReactNode;
    size?: IconButtonSizeType | object;
    variation?: IconButtonVariationType;
    variant?: IconButtonVariantType;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement & HTMLButtonElement & HTMLDivElement>) => void;
    disabled?: boolean;
    loading?: boolean;
}

export const IconButton: React.FC<ButtonIconPropsInterface> = ({
    icon,
    variant = 'default',
    size = 'medium',
    variation = 'filled',
    onClick,
    disabled = false,
    loading = false,
}) => {
    return (
        <Wrapper onClick={onClick} size={size} variant={variant} variation={variation} disabled={disabled}>
            {loading ? <IconSpinner /> : icon}
        </Wrapper>
    );
};
