import React, { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Content, Wrapper, Trigger, IconWrapper, TriggerChildren } from './tooltip.styled';
import { IconHelpFilled } from '../icons/help-filled';

type TooltipProps = {
    children: React.ReactNode;
    content: JSX.Element | string;
    position?: 'bottom' | 'top' | 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left';
    size?: 'small' | 'medium';
    icon?: boolean;
    arrowPosition?: number;
    block?: boolean;
};

export const Tooltip: React.FC<TooltipProps> = ({
    children,
    content,
    position = 'top-left',
    size = 'medium',
    icon = false,
    arrowPosition,
    block = false,
}) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number } | null>(null);
    const [tooltipContent, setTooltipContent] = useState(content);
    const triggerRef = useRef<HTMLDivElement>(null);
    const iconRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const calculateTooltipPosition = () => {
        if (tooltipRef.current && (triggerRef.current || iconRef.current)) {
            const triggerElement = icon ? iconRef.current : triggerRef.current;

            if (triggerElement) {
                const triggerRect = triggerElement.getBoundingClientRect();
                const tooltipRect = tooltipRef.current.getBoundingClientRect();

                let top = 0;
                let left = 0;

                switch (position) {
                    case 'bottom':
                        top = triggerRect.bottom + window.scrollY;
                        left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2 + window.scrollX;
                        break;
                    case 'top':
                        top = triggerRect.top - tooltipRect.height + window.scrollY;
                        left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2 + window.scrollX;
                        break;
                    case 'top-left':
                        top = triggerRect.top - tooltipRect.height + window.scrollY;
                        left = triggerRect.left + window.scrollX;
                        break;
                    case 'top-right':
                        top = triggerRect.top - tooltipRect.height + window.scrollY;
                        left = triggerRect.right - tooltipRect.width + window.scrollX;
                        break;
                    case 'bottom-left':
                        top = triggerRect.bottom + window.scrollY;
                        left = triggerRect.left + window.scrollX;
                        break;
                    case 'bottom-right':
                        top = triggerRect.bottom + window.scrollY;
                        left = triggerRect.right - tooltipRect.width + window.scrollX;
                        break;
                    default:
                        top = triggerRect.bottom + window.scrollY;
                        left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2 + window.scrollX;
                        break;
                }

                setTooltipPosition({ top, left });
            }
        }
    };

    useEffect(() => {
        if (tooltipVisible) {
            calculateTooltipPosition();
        }
    }, [tooltipVisible, tooltipContent]);

    useEffect(() => {
        setTooltipContent(content);
    }, [content]);

    const handleMouseEnter = () => {
        setTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setTooltipVisible(false);
    };

    return (
        <Wrapper cursor={icon} block={block}>
            <Trigger ref={triggerRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} block={block}>
                <TriggerChildren block={block}>{children}</TriggerChildren>

                {icon && (
                    <IconWrapper ref={iconRef}>
                        <IconHelpFilled />
                    </IconWrapper>
                )}
            </Trigger>
            {tooltipVisible &&
                createPortal(
                    <Content
                        ref={tooltipRef}
                        arrowPosition={arrowPosition}
                        icon={icon}
                        position={position}
                        size={size}
                        style={{ top: tooltipPosition?.top, left: tooltipPosition?.left }}
                    >
                        {content}
                    </Content>,
                    document.body,
                )}
        </Wrapper>
    );
};

export default Tooltip;
