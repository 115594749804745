import styled, { css, DefaultTheme } from 'styled-components';
import { BreakpointSizeType } from '../../../../theme/type/breakpoints-type';
import {
    IconButtonSizeType,
    IconButtonVariantType,
    IconButtonVariationType,
} from '../../../../theme/type/icon-button-type';

const getCssIconButtonSize = (sizeKey: IconButtonSizeType, theme: DefaultTheme) => `
    width: ${theme.iconButton.size[sizeKey].wrapper};
    min-width: ${theme.iconButton.size[sizeKey].wrapper};
    height: ${theme.iconButton.size[sizeKey].wrapper};

    i {
        width: ${theme.iconButton.size[sizeKey].icon};
        min-width: ${theme.iconButton.size[sizeKey].icon};
        height: ${theme.iconButton.size[sizeKey].icon};
    }
`;

const getResponsiveIconWrapperSize = (size: object, theme: DefaultTheme) => {
    return Object.entries(size).map((item, index) => {
        const breakpointKey = item[0] as BreakpointSizeType;
        const iconSizeKey = item[1] as IconButtonSizeType;

        if (index === 0) {
            return `
                @media screen and (min-width: 0px) {
                    ${getCssIconButtonSize(iconSizeKey, theme)}
                }
            `;
        }
        return `
            @media ${theme.breakpoints[breakpointKey].min} {
                ${getCssIconButtonSize(iconSizeKey, theme)}
            }
        `;
    });
};

export const Wrapper = styled.button.withConfig({
    shouldForwardProp: (prop) => !['variant', 'size', 'variation', 'disabled'].includes(prop),
})<{
    variant: IconButtonVariantType;
    size: IconButtonSizeType | object;
    variation: IconButtonVariationType;
    disabled: boolean;
}>`
    ${({ theme, variant, variation, size, onClick, disabled }) => css`
        display: inline-flex;
        position: relative;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        transition: all ${theme.base.transition};
        color: ${theme.iconButton.variant[variant][variation].base.color};
        background-color: ${theme.iconButton.variant[variant][variation].base.background};
        border: 0.1rem solid ${theme.iconButton.variant[variant][variation].base.border};
        cursor: ${onClick ? 'pointer' : 'unset'};

        ${!disabled &&
        onClick &&
        css`
            &:hover {
                color: ${theme.iconButton.variant[variant][variation].hover.color};
                background-color: ${theme.iconButton.variant[variant][variation].hover.background};
                border: 0.1rem solid ${theme.iconButton.variant[variant][variation].hover.border};
            }

            &:active {
                color: ${theme.iconButton.variant[variant][variation].active.color};
                background-color: ${theme.iconButton.variant[variant][variation].active.background};
                border: 0.1rem solid ${theme.iconButton.variant[variant][variation].active.border};
            }
        `}

        ${disabled &&
        css`
            color: ${theme.iconButton.variant[variant][variation].disabled.color};
            background-color: ${theme.iconButton.variant[variant][variation].disabled.background};
            border: 0.1rem solid ${theme.iconButton.variant[variant][variation].disabled.border};
            cursor: not-allowed;
        `}

        ${typeof size === 'string' &&
        css`
            ${getCssIconButtonSize(size, theme)};
        `}

        ${typeof size === 'object' &&
        css`
            ${getResponsiveIconWrapperSize(size, theme)};
        `}
    `}
`;
