import styled, { css, DefaultTheme } from 'styled-components';
import { GapSizeType } from '../../../theme/type/gap-type';
import { BreakpointSizeType } from '../../../theme/type/breakpoints-type';
import { TextSizeType, TextVariantType } from '../../../theme/type/text-type';

const getResponsiveSize = (size: object, theme: DefaultTheme) => {
    return Object.entries(size).map((item, index) => {
        if (index === 0) {
            return `
            @media ${theme.breakpoints[item[0] as BreakpointSizeType].max} {
                font-size: ${theme.text.size[item[1] as TextSizeType].fontSize};
                line-height: ${theme.text.size[item[1] as TextSizeType].lineHeight};
            }
                @media ${theme.breakpoints[item[0] as BreakpointSizeType].min} {
                    font-size: ${theme.text.size[item[1] as TextSizeType].fontSize};
                    line-height: ${theme.text.size[item[1] as TextSizeType].lineHeight};
                }
            `;
        }
        return `
            @media ${theme.breakpoints[item[0] as BreakpointSizeType].min} {
                font-size: ${theme.text.size[item[1] as TextSizeType].fontSize};
                line-height: ${theme.text.size[item[1] as TextSizeType].lineHeight};
            }
        `;
    });
};

const getResponsiveAlign = (align: object, theme: DefaultTheme) => {
    return Object.entries(align).map((item, index) => {
        if (index === 0) {
            return `
                @media ${theme.breakpoints[item[0] as BreakpointSizeType].max} {
                    text-align: ${item[1]};
                }
                @media ${theme.breakpoints[item[0] as BreakpointSizeType].min} {
                    text-align: ${item[1]};
                }
            `;
        }
        return `
            @media ${theme.breakpoints[item[0] as BreakpointSizeType].min} {
                text-align: ${item[1]};
            }
        `;
    });
};

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) =>
        ![
            'gap',
            'marginTop',
            'marginBottom',
            'marginLeft',
            'marginRight',
            'align',
            'variant',
            'size',
            'bold',
            'semiBold',
            'italic',
            'uppercase',
            'nowrap',
        ].includes(prop),
})<{
    gap: GapSizeType;
    marginTop: GapSizeType;
    marginBottom: GapSizeType;
    marginLeft: GapSizeType;
    marginRight: GapSizeType;
    align?: string | object;
    variant?: TextVariantType;
    size: TextSizeType | object;
    bold?: boolean;
    semiBold?: boolean;
    italic?: boolean;
    uppercase?: boolean;
    nowrap?: boolean;
}>`
    ${({
        theme,
        size,
        gap,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        variant,
        align,
        bold,
        semiBold,
        italic,
        uppercase,
        nowrap,
    }) => css`
        margin: 0 0 ${theme.gap[gap]};
        font-weight: 400;

        ${marginTop &&
        css`
            margin-top: ${theme.gap[marginTop]};
        `}

        ${marginBottom &&
        css`
            margin-bottom: ${theme.gap[marginBottom]};
        `}

        ${marginLeft &&
        css`
            margin-left: ${theme.gap[marginLeft]};
        `}

        ${marginRight &&
        css`
            margin-right: ${theme.gap[marginRight]};
        `}

        ${typeof size === 'object' &&
        css`
            ${getResponsiveSize(size, theme)}
        `}

        ${typeof size === 'string' &&
        css`
            font-size: ${theme.text.size[size].fontSize};
            line-height: ${theme.text.size[size].lineHeight};
        `}

        ${typeof align === 'object' &&
        css`
            ${getResponsiveAlign(align, theme)}
        `}

        ${typeof align === 'string' &&
        css`
            text-align: ${align};
        `}

        ${variant &&
        css`
            color: ${theme.text.variant[variant].text};
        `}

        ${semiBold &&
        css`
            font-weight: 500;
        `}

        ${bold &&
        css`
            font-weight: 700;
        `}

        ${italic &&
        css`
            font-style: italic;
        `}

        ${uppercase &&
        css`
            text-transform: uppercase;
        `}

        ${nowrap &&
        css`
            white-space: nowrap;
        `}
    `}
`;
