import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconDiscord: React.FC = () => (
    <Icon viewBox="0 0 16 13" fill="none">
        <path
            d="M13.538 1.0134C12.5247 0.541002 11.4275 0.198136 10.2846 3.50472e-05C10.2746 -0.000285421 10.2646 0.00159507 10.2554 0.00554435C10.2462 0.00949362 10.2379 0.0154158 10.2313 0.022893C10.0941 0.274328 9.93411 0.601956 9.82744 0.853392C8.61522 0.67053 7.38242 0.67053 6.1702 0.853392C6.06353 0.594337 5.90353 0.274328 5.75876 0.022893C5.75114 0.00765446 5.72828 3.50472e-05 5.70542 3.50472e-05C4.56254 0.198136 3.47298 0.541002 2.452 1.0134C2.44438 1.0134 2.43676 1.02102 2.42914 1.02863C0.356706 4.12967 -0.214738 7.1469 0.0671745 10.1336C0.0671745 10.1489 0.0747937 10.1641 0.0900322 10.1717C1.4615 11.1775 2.77963 11.787 4.08252 12.1908C4.10538 12.1985 4.12824 12.1908 4.13586 12.1756C4.44063 11.7565 4.71492 11.3146 4.95112 10.8499C4.96636 10.8194 4.95112 10.7889 4.92064 10.7813C4.48634 10.6137 4.0749 10.4156 3.67108 10.187C3.64061 10.1717 3.64061 10.126 3.66346 10.1032C3.74728 10.0422 3.83109 9.97364 3.9149 9.91269C3.93014 9.89745 3.953 9.89745 3.96823 9.90507C6.58926 11.1013 9.416 11.1013 12.0065 9.90507C12.0218 9.89745 12.0446 9.89745 12.0599 9.91269C12.1437 9.98126 12.2275 10.0422 12.3113 10.1108C12.3418 10.1336 12.3418 10.1794 12.3037 10.1946C11.9075 10.4308 11.4884 10.6213 11.0541 10.7889C11.0237 10.7965 11.016 10.8346 11.0237 10.8575C11.2675 11.3223 11.5418 11.7642 11.8389 12.1832C11.8618 12.1908 11.8846 12.1985 11.9075 12.1908C13.218 11.787 14.5361 11.1775 15.9076 10.1717C15.9228 10.1641 15.9305 10.1489 15.9305 10.1336C16.2657 6.68212 15.3743 3.68776 13.5685 1.02863C13.5609 1.02102 13.5533 1.0134 13.538 1.0134ZM5.34732 8.31264C4.56254 8.31264 3.90728 7.58881 3.90728 6.69736C3.90728 5.80591 4.5473 5.08208 5.34732 5.08208C6.15496 5.08208 6.79498 5.81353 6.78736 6.69736C6.78736 7.58881 6.14734 8.31264 5.34732 8.31264ZM10.6579 8.31264C9.87316 8.31264 9.2179 7.58881 9.2179 6.69736C9.2179 5.80591 9.85792 5.08208 10.6579 5.08208C11.4656 5.08208 12.1056 5.81353 12.098 6.69736C12.098 7.58881 11.4656 8.31264 10.6579 8.31264Z"
            fill="currentColor"
        />
    </Icon>
);
